<template>
  <div class="a-menage-page">
    <div class="a-menage-left">
      <el-form style="  padding: 0 20px;">
        <el-form-item>
          <el-input
            size="small"
            v-model="value"
            placeholder="请输入关键字"
            clearable
          ></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-input size="small" placeholder="请输入备注"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            style="width:100%; border-radius: 16px;"
            @click="searchFun"
            :loading="loading"
          >
            查询
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            @click="addUser"
            style="width:100%; border-radius: 16px;background:#746D99;border:none"
          >
            新增用户
          </el-button>
        </el-form-item>
      </el-form>
      <div class="left-log">
        <logCom
          ref="manageLogCom"
          :logList="logList"
          title="近10条查询记录"
          @selectLog="selectLog"
        />
      </div>
    </div>
    <div class="a-menage-right">
      <el-table
        :data="tableList"
        :height="pageHeight - 215"
        maxHeight="1200"
        style="width: 100%"
        :row-style="{ height: 0 + 'px' }"
        :cell-style="{ padding: '5px 0' }"
        header-row-class-name="tableheader"
        v-loading="loading"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="menage-expand">
              <el-descriptions
                :column="1"
                :labelStyle="{ width: '60px', textAlign: 'right' }"
              >
                <el-descriptions-item label="账号ID">
                  {{ props.row.account_id }}
                </el-descriptions-item>
                <el-descriptions-item label="登录名">
                  {{ props.row.account_name }}
                </el-descriptions-item>
                <el-descriptions-item label="过期时间">
                  {{ props.row.expire_time }}
                </el-descriptions-item>
                <el-descriptions-item label="权限">
                  {{ props.row.permissions_list.join(',') }}
                </el-descriptions-item>
                <el-descriptions-item label="积分余额">
                  {{ props.row.balance }}
                </el-descriptions-item>

                <el-descriptions-item label="启用时间">
                  {{ props.row.start_time }}
                </el-descriptions-item>
                <el-descriptions-item label="创建时间">
                  {{ props.row.create_time }}
                </el-descriptions-item>
                <el-descriptions-item label="创建人ID">
                  {{ props.row.create_account_id }}
                </el-descriptions-item>
                <el-descriptions-item label="备注">
                  {{ props.row.remark }}
                </el-descriptions-item>
                <!-- <el-descriptions-item label="操作">
                  <el-button type="primary" size="mini" @click="update">
                    编辑
                  </el-button>
                  <el-button type="warning" size="mini">密码修改</el-button>
                </el-descriptions-item> -->
              </el-descriptions>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="登录名" prop="account_name"></el-table-column>
        <el-table-column label="积分余额" prop="balance"></el-table-column>
        <el-table-column label="启用时间" prop="start_time"></el-table-column>
        <el-table-column label="过期时间" prop="expire_time"></el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
        <el-table-column label="操作" align="center" width="180">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="update(scope.row)">
              编辑
            </el-button>
            <el-button
              type="warning"
              size="mini"
              @click="modify(scope.row.account_name)"
            >
              密码修改
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        class="channel-pagination-child"
        :total="total"
        margin="5px 0 0 0"
        :page-size="limit"
        :currentPage="current"
        :pagerCount="5"
        :background="false"
        :sizesArr="[20, 50, 100]"
        @currentChange="currentChange"
        @sizeChange="sizeChange"
      />
    </div>
    <!--编辑 弹框 -->

    <el-dialog
      :title="dialogTitle == 1 ? '新增用户' : '编辑用户'"
      :visible.sync="dialogVisible"
      width="40%"
      class="m-add-dialog"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="ruleFormRules"
          ref="ruleUserForm"
          label-width="80px"
          class="demo-ruleForm"
          :inline="true"
          style="overflow:hidden"
        >
          <el-form-item
            label="用户名"
            prop="account_name"
            style="width:calc(50% - 10px)"
            v-if="dialogTitle == 1"
          >
            <el-input
              style="width:100%"
              placeholder="用户名"
              v-model.trim="ruleForm.account_name"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="密码"
            prop="password"
            style="width:calc(50% - 10px)"
            v-if="dialogTitle == 1"
          >
            <el-input
              style="width:100%"
              placeholder="密码"
              v-model="ruleForm.password"
            ></el-input>
          </el-form-item>
          <el-form-item label="积分余额" style="width:100%">
            <el-input
              style="width:calc(100% - 10px)"
              placeholder="积分余额"
              v-model.number="ruleForm.balance"
            ></el-input>
          </el-form-item>

          <el-form-item label="权限" style="width:100%">
            <el-checkbox-group v-model="ruleForm.permissions_list">
              <el-checkbox
                :label="item"
                v-for="(item, i) in permissionList"
                :key="i"
                :name="item"
              ></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            label="启用时间"
            style="width:calc(50% - 10px)"
            prop="start_time"
          >
            <el-date-picker
              v-model="ruleForm.start_time"
              type="datetime"
              style="width:100%"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间"
            ></el-date-picker>
          </el-form-item>

          <el-form-item
            label="过期时间"
            style="width:calc(50% - 10px)"
            prop="expire_time"
          >
            <el-date-picker
              style="width:100%"
              v-model="ruleForm.expire_time"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="备注" style="width:100%">
            <el-input
              type="textarea"
              style="width:calc(100% - 10px)"
              v-model="ruleForm.remark"
              placeholder="备注"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" v-if="dialogTitle == 1" @click="addUserFun">
          确 定
        </el-button>
        <el-button type="primary" v-else @click="updateFun">
          确 定
        </el-button>
      </span>
    </el-dialog>

    <!-- 密码 弹框 -->

    <el-dialog
      :title="'密码修改'"
      :visible.sync="passwordVisible"
      width="30%"
      class="m-add-dialog"
    >
      <div>
        <el-form
          :model="passwordForm"
          :rules="rules"
          ref="passwordForm"
          label-width="70px"
          class="demo-ruleForm"
          :inline="true"
          style="overflow:hidden"
        >
          <el-form-item
            label="新密码"
            prop="password"
            style="width:calc(100% - 10px)"
          >
            <el-input
              style="width:100%"
              placeholder="新密码"
              v-model="passwordForm.password"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="passwordVisible = false">取 消</el-button>
        <el-button type="primary" @click="passwordFun">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import tableComponent from '@/views/components/TableCom.vue'
import logCom from '@/views/components/LogCom.vue'
import {
  userList,
  changePassword,
  accountPermission,
  addAccount,
  updateAccount
} from '@/api/manage'
export default {
  components: {
    tableComponent,
    logCom
  },
  data() {
    const validateTest = (rule, value, callback) => {
      if (value === '' || value === null) {
        return true
      } else {
        const reg = /^.*(?=.{6,16})(?=.*\d)(?=.*[A-Z]{1,})(?=.*[a-z]{1,})(?=.*[\.!@#$%^&*?\(\)]).*$/
        if (reg.test(value)) {
          return callback()
        }
        callback(new Error('必须包含大写字母、小写字母、数字、特殊字符'))
      }
    }
    return {
      loading: false,
      logList: [], //查询记录

      current: 1,

      limit: 20,

      total: 0,
      // 新增弹框
      dialogVisible: false,
      dialogTitle: 1,
      ruleForm: {
        account_name: '',
        password: '',
        balance: '',
        start_time: '',
        expire_time: '',
        permissions_list: [],
        remark: ''
      },
      ruleFormRules: {
        account_name: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        expire_time: [
          {
            required: true,
            message: '请选择过期时间',
            trigger: 'change'
          }
        ]
      },
      // 密码弹框
      rules: {
        password: [
          { required: true, message: '密码不能为空！', trigger: 'blur' },
          {
            validate: validateTest,
            trigger: 'blur'
          }
        ]
      },
      passwordForm: {
        name: '',
        password: '',
        account_name: ''
      },

      passwordVisible: false,

      tableList: [],
      value: '',
      // 权限列表
      permissionList: []
    }
  },
  computed: {
    pageHeight() {
      return this.$store.state.windowData.height
    }
  },
  created() {
    this.getLocalStorageFun()
    this.getUserList()
    this.getAccountPermission() //用户权限
  },
  methods: {
    // 获取用户权限
    getAccountPermission() {
      accountPermission().then(res => {
        this.permissionList = res.data.data.permissions_str_list
      })
    },
    // 添加用户
    addUserFun() {
      this.$refs['ruleUserForm'].validate(valid => {
        if (valid) {
          const reg = /^.*(?=.{6,16})(?=.*\d)(?=.*[A-Z]{1,})(?=.*[a-z]{1,})(?=.*[\.!@#$%^&*?\(\)]).*$/
          if (!reg.test(this.ruleForm.password)) {
            this.$message({
              type: 'warning',
              message: '必须包含大写字母、小写字母、数字、特殊字符!'
            })
            return
          }
          addAccount(this.ruleForm).then(res => {
            if (res.status == 200) {
              this.$message({
                type: 'success',
                message: '新增成功！'
              })
              this.current = 1
              this.getUserList()
              this.dialogVisible = false
            }
          })
        }
      })
    },
    // 编辑用户
    updateFun() {
      this.$refs['ruleUserForm'].validate(valid => {
        if (valid) {
          updateAccount(this.ruleForm).then(res => {
            if (res.status == 200) {
              this.$message({
                type: 'success',
                message: '编辑成功！'
              })
              this.current = 1
              this.getUserList()
              this.dialogVisible = false
            }
          })
        }
      })
    },
    searchFun() {
      this.$refs.manageLogCom.selectIndex = null
      this.current = 1
      this.localStorageFun()
      this.getLocalStorageFun()
      this.getUserList()
    },
    selectLog(row) {
      this.value = row.target_account
      this.getUserList()
    },
    getUserList() {
      let obj = {
        page_index: this.current,
        page_size: this.limit,
        search: this.value
      }
      this.loading = true
      userList(obj).then(res => {
        this.total = res.data.data.count
        this.tableList = res.data.data.task_list
        this.loading = false
      })
    },
    currentChange(val) {
      this.current = val
      this.getUserList()
    },

    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getUserList()
    },
    handleClick(row) {
      this.$router.push('/history/log/detail')
    },
    // 新增用户弹框按钮
    addUser() {
      this.dialogTitle = 1
      this.ruleForm.permissions_list = []
      this.ruleForm.account_name = ''
      this.ruleForm.password = ''
      this.ruleForm.balance = ''
      this.ruleForm.start_time = ''
      this.ruleForm.expire_time = ''
      this.ruleForm.remark = ''
      this.dialogVisible = true
    },
    // 编辑用户弹框按钮
    update(row) {
      this.dialogTitle = 0
      this.ruleForm = Object.assign({}, row)
      this.dialogVisible = true
    },
    // 密码修改
    modify(name) {
      this.passwordForm.password = ''
      this.passwordForm.account_name = name
      this.passwordVisible = true
    },
    passwordFun() {
      this.$refs['passwordForm'].validate(valid => {
        if (valid) {
          const reg = /^.*(?=.{6,16})(?=.*\d)(?=.*[A-Z]{1,})(?=.*[a-z]{1,})(?=.*[\.!@#$%^&*?\(\)]).*$/
          if (!reg.test(this.passwordForm.password)) {
            this.$message({
              type: 'warning',
              message: '必须包含大写字母、小写字母、数字、特殊字符!'
            })
            return
          }
          changePassword({
            account_name: this.passwordForm.account_name,
            password: this.passwordForm.password
          }).then(res => {
            if (res.status == 200) {
              this.$message({
                type: 'success',
                message: '修改成功！'
              })
              this.passwordVisible = false
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 查询记录
    localStorageFun() {
      let arrString = localStorage.getItem('administratorsPageSearch')
      let arr = JSON.parse(arrString)
      if (arr && Array.isArray(arr)) {
        if (arr.length < 10) {
          if (this.value.trim() !== '') {
            arr.push({ target_account: this.value })
          }
        } else {
          arr.shift()
          if (this.value.trim() !== '') {
            arr.push({ target_account: this.value })
          }
        }
      } else {
        arr = []
        if (this.value.trim() !== '') {
          arr.push({ target_account: this.value })
        }
      }
      localStorage.setItem('administratorsPageSearch', JSON.stringify(arr))
    },
    getLocalStorageFun() {
      let arrString = localStorage.getItem('administratorsPageSearch')
      let arr = JSON.parse(arrString)
      if (arr && Array.isArray(arr)) {
        this.logList = [].concat(arr)
      }
    },
    isEmpty(obj) {
      let arr = []
      for (let i in obj) {
        if (obj[i] && obj[i].trim() !== '') {
          arr.push(obj[i])
        }
      }
      if (arr.length == 0) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.a-menage-page {
  width: 100%;
  height: 100%;

  overflow: hidden;
  display: flex;
  justify-content: space-between;

  .a-menage-left {
    width: 259px;
    height: 100%;
    border-right: 1px solid #e4dede;

    overflow: hidden;
    ::v-deep .el-form-item {
      margin-bottom: 10px;
    }
    .left-log {
      width: 100%;
      height: calc(100% - 225px);
      overflow: hidden;
      margin-top: 30px;
    }
  }
  .a-menage-right {
    width: calc(100% - 270px);
    height: 100%;
    background: white;
    ::v-deep .tableheader th {
      background: rgb(243, 247, 249);
      padding: 8px 0;
    }
    ::v-deep .el-table__expanded-cell {
      padding: 0;
    }
    .menage-expand {
      background: #f5f9fb;
      padding: 15px 20px;
      ::v-deep .el-descriptions__body {
        background: none !important;
      }
      ::v-deep .el-descriptions-row {
        background: none !important;
      }
    }
  }
}
.m-add-dialog {
  ::v-deep .el-form-item__content {
    width: calc(100% - 80px);
  }
}
</style>
