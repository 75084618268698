<template>
  <div class="administrators">
    <div class="a-box">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="历史记录" name="first">
          <div class="tab-content">
            <historyCom />
          </div>
        </el-tab-pane>
        <el-tab-pane label="用户管理" name="second">
          <div class="tab-content">
            <manageCom />
          </div>
        </el-tab-pane>
        <el-tab-pane label="网站站点" name="third">
          <div class="tab-content">
            <websiteCom />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import historyCom from './page/History.vue'
import manageCom from './page/Management.vue'
import websiteCom from './page/Website.vue'
export default {
  data() {
    return {
      activeName: 'first'
    }
  },
  components: { historyCom, manageCom, websiteCom },
  methods: {
    handleClick(data) {}
  }
}
</script>

<style lang="scss" scoped>
.administrators {
  width: 100%;
  height: calc(100vh - 50px);
  padding: 10px;
  background: rgb(240, 242, 245);
  .a-box {
    background: white;
    padding: 5px 10px;
    ::v-deep .el-tabs__item:hover {
      color: #ff9000;
    }
    ::v-deep .el-tabs__item.is-active {
      color: #ff9000;
    }
    ::v-deep .el-tabs__active-bar {
      background: #ff9000;
    }

    .tab-content {
      width: 100%;
      height: calc(100vh - 165px);
      overflow: hidden;
    }
  }
}
</style>
