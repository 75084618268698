<template>
  <div class="a-menage-page">
    <div class="a-menage-left">
      <el-form style="  padding: 0 20px;">
        <el-form-item>
          <el-input
            size="small"
            v-model="search"
            placeholder="请输入关键字"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            size="small"
            type="primary"
            style="width:100%; border-radius: 16px;"
            @click="searchFun"
            :loading="loading"
          >
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <div class="left-log">
        <logCom
          :logList="logList"
          title="近10条查询记录"
          @selectLog="selectLog"
          ref="webSiteLogCom"
        />
      </div>
    </div>
    <div class="a-menage-right">
      <el-table
        :data="tableList"
        :height="pageHeight - 215"
        maxHeight="1200"
        margin="5px 0 0 0"
        style="width: 100%"
        :row-style="{ height: 0 + 'px' }"
        :cell-style="{ padding: '5px 0' }"
        header-row-class-name="tableheader"
        v-loading="loading"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="menage-expand">
              <el-descriptions
                :column="1"
                :labelStyle="{ width: '120px', textAlign: 'right' }"
              >
                <el-descriptions-item label="网站编号">
                  {{ props.row.id }}
                </el-descriptions-item>
                <el-descriptions-item label="网站名">
                  {{ props.row.name }}
                </el-descriptions-item>
                <el-descriptions-item label="网站主页">
                  {{ props.row.index }}
                </el-descriptions-item>
                <el-descriptions-item label="网站简介">
                  {{ props.row.desc }}
                </el-descriptions-item>
                <el-descriptions-item label="网站logo">
                  {{ props.row.logo }}
                </el-descriptions-item>

                <el-descriptions-item label="手机接口">
                  {{ props.row.support_phone === 1 ? '支持' : '不支持' }}
                </el-descriptions-item>
                <el-descriptions-item label="邮箱接口">
                  {{ props.row.support_email === 1 ? '支持' : '不支持' }}
                </el-descriptions-item>
                <el-descriptions-item label="用户名接口">
                  {{ props.row.support_uname === 1 ? '支持' : '不支持' }}
                </el-descriptions-item>
                <el-descriptions-item label="更多信息接口">
                  {{ props.row.more === 1 ? '支持' : '不支持' }}
                </el-descriptions-item>
                <el-descriptions-item label="创建时间">
                  {{ props.row.create_time }}
                </el-descriptions-item>
                <el-descriptions-item label="接口最后检查时间">
                  {{ props.row.last_check_time }}
                </el-descriptions-item>
                <el-descriptions-item label="接口检测异常次数">
                  {{ props.row.exception_cnt }}
                </el-descriptions-item>
                <el-descriptions-item label="备注">
                  {{ props.row.remark }}
                </el-descriptions-item>
                <!-- <el-descriptions-item label="操作">
                  <el-button type="primary" size="mini">检测记录</el-button>
                </el-descriptions-item> -->
              </el-descriptions>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="网站名" prop="name"></el-table-column>
        <el-table-column label="手机" prop="support_phone"></el-table-column>
        <el-table-column label="邮箱" prop="support_email"></el-table-column>
        <el-table-column label="用户名" prop="support_uname"></el-table-column>
        <el-table-column label="更多信息" prop="more"></el-table-column>
        <el-table-column
          label="接口最后检查时间"
          prop="last_check_time"
        ></el-table-column>
        <el-table-column
          label="接口检测异常次数"
          prop="exception_cnt"
        ></el-table-column>
        <el-table-column label="操作" width="110" align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="record(scope.row)">
              检测记录
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        class="channel-pagination-child"
        :total="total"
        margin="10px 0"
        :page-size="limit"
        :currentPage="current"
        :pagerCount="5"
        :background="false"
        :sizesArr="[20, 50, 100]"
        @currentChange="currentChange"
        @sizeChange="sizeChange"
      />
    </div>

    <!-- 弹框 -->
    <el-dialog
      :title="recordObj.name + ' 历史检测记录'"
      :visible.sync="dialogVisible"
      width="50%"
      class="m-add-dialog"
    >
      <div>
        <el-table
          :data="dialogTable"
          :height="400"
          style="width: 100%"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
          header-row-class-name="tableheader"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-form
                label-position="left"
                :inline="false"
                class="demo-table-expand"
              >
                <el-form-item label="网站编号">
                  <span>{{ props.row.website_id }}</span>
                </el-form-item>
                <el-form-item label="测试帐号">
                  <span>{{ props.row.target_account }}</span>
                </el-form-item>
                <el-form-item label="测试类型">
                  <span>{{ props.row.target_type }}</span>
                </el-form-item>
                <el-form-item label="测试返回值">
                  <span>{{ props.row.result }}</span>
                </el-form-item>
                <el-form-item label="异常返回值">
                  <span>{{ props.row.logo }}</span>
                </el-form-item>
                <el-form-item label="结果">
                  <span>{{ props.row.state === 1 ? '正常' : '异常' }}</span>
                </el-form-item>

                <el-form-item label="检查时间">
                  <span>{{ props.row.create_time }}</span>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column
            label="检查时间"
            prop="create_time"
          ></el-table-column>
          <el-table-column
            label="测试类型"
            prop="target_type"
          ></el-table-column>
          <el-table-column label="测试结果" prop="state"></el-table-column>
        </el-table>
        <pagination
          class="channel-pagination-child"
          :total="dialogTotal"
          margin="10px 0"
          :page-size="dialogLimit"
          :currentPage="dialogCurrent"
          :pagerCount="5"
          :background="false"
          layout="total,pager,jumper"
          :sizesArr="[10, 20, 50]"
          @currentChange="dialogCurrentChange"
          @sizeChange="dialogSizeChange"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import tableComponent from '@/views/components/TableCom.vue'
import logCom from '@/views/components/LogCom.vue'
import { websites, websitesApi } from '@/api/manage'
export default {
  components: {
    tableComponent,
    logCom
  },
  data() {
    return {
      loading: false,
      logList: [], //查询记录
      search: '',
      current: 1,
      limit: 20,
      total: 0,
      // 弹框
      dialogVisible: false,
      dialogTotal: 0,
      dialogLimit: 10,
      dialogCurrent: 1,
      recordObj: {},
      dialogTable: [],
      tableList: []
    }
  },
  computed: {
    pageHeight() {
      return this.$store.state.windowData.height
    }
  },
  created() {
    this.getwebsites()
    this.getLocalStorageFun()
  },
  methods: {
    searchFun() {
      this.current = 1
      this.$refs.webSiteLogCom.selectIndex = null
      this.localStorageFun()
      this.getLocalStorageFun()
      this.getwebsites()
    },
    selectLog(row) {
      this.search = row.target_account
      this.getwebsites()
    },
    getwebsites() {
      let obj = {
        page_index: this.current,
        page_size: this.limit,

        search: this.search
      }
      this.loading = true
      websites(obj).then(res => {
        this.total = res.data.data.count
        this.tableList = res.data.data.website_list
        this.loading = false
      })
    },
    currentChange(val) {
      this.current = val
      this.getwebsites()
    },

    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getwebsites()
    },
    handleClick(row) {
      this.$router.push('/history/log/detail')
    },
    record(row) {
      this.recordObj = Object.assign({}, row)
      this.dialogCurrent = 1
      this.dialogTable = []
      this.getWebsitesApi().then(res => {
        this.dialogTotal = res.data.data.count
        this.dialogTable = res.data.data.history_list
        this.dialogVisible = true
      })
    },
    // 检测记录接口
    getWebsitesApi() {
      return new Promise((resolve, reject) => {
        let obj = {
          website_id: this.recordObj.id,
          page_index: this.dialogCurrent,
          page_size: this.dialogLimit
        }
        websitesApi(obj).then(res => {
          resolve(res)
        })
      })
    },
    // 弹框分页
    dialogCurrentChange(val) {
      this.dialogCurrent = val
    },

    dialogSizeChange(val) {
      this.dialogCurrent = 1
      this.dialogLimit = val
    },
    // 查询记录
    localStorageFun() {
      let arrString = localStorage.getItem('webSitePageSearch')
      let arr = JSON.parse(arrString)
      if (arr && Array.isArray(arr)) {
        if (arr.length < 10) {
          if (this.search.trim() !== '') {
            arr.push({ target_account: this.search })
          }
        } else {
          arr.shift()
          if (this.search.trim() !== '') {
            arr.push({ target_account: this.search })
          }
        }
      } else {
        arr = []
        if (this.search.trim() !== '') {
          arr.push({ target_account: this.search })
        }
      }
      localStorage.setItem('webSitePageSearch', JSON.stringify(arr))
    },
    getLocalStorageFun() {
      let arrString = localStorage.getItem('webSitePageSearch')
      let arr = JSON.parse(arrString)
      if (arr && Array.isArray(arr)) {
        this.logList = [].concat(arr)
      }
    },
    isEmpty(obj) {
      let arr = []
      for (let i in obj) {
        if (obj[i] && obj[i].trim() !== '') {
          arr.push(obj[i])
        }
      }
      if (arr.length == 0) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.a-menage-page {
  width: 100%;
  height: 100%;

  overflow: hidden;
  display: flex;
  justify-content: space-between;

  .a-menage-left {
    width: 259px;
    height: 100%;
    border-right: 1px solid #e4dede;

    overflow: hidden;
    ::v-deep .el-form-item {
      margin-bottom: 10px;
    }
    .left-log {
      width: 100%;
      height: calc(100% - 225px);
      overflow: hidden;
      margin-top: 30px;
    }
  }
  .a-menage-right {
    width: calc(100% - 270px);
    height: 100%;
    background: white;
    ::v-deep .tableheader th {
      background: rgb(243, 247, 249);
      padding: 8px 0;
    }
    ::v-deep .el-table__expanded-cell {
      padding: 0;
    }
    .menage-expand {
      background: #f5f9fb;
      padding: 15px 20px;
      ::v-deep .el-descriptions__body {
        background: none !important;
      }
      ::v-deep .el-descriptions-row {
        background: none !important;
      }
    }
  }
  .demo-table-expand .el-form-item {
    margin-bottom: 0 !important;
  }
}
</style>
