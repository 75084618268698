import request from '@/plugins/axios'

export function logList(data) {
  return request({
    url: `/api/v1/task/view_task`,
    method: 'get',
    params: data
  })
}
export function userList(data) {
  return request({
    url: `/api/v1/manager/view_account`,
    method: 'get',
    params: data
  })
}

export function websites(data) {
  return request({
    url: `/api/v1/system/websites`,
    method: 'get',
    params: data
  })
}

export function websitesApi(data) {
  return request({
    url: `/api/v1/system/check_api_historys`,
    method: 'get',
    params: data
  })
}

export function changePassword(data) {
  return request({
    url: `/api/v1/manager/change_password`,
    method: 'post',
    data
  })
}

export function accountPermission(data) {
  return request({
    url: `/api/v1/account/get_account_permission`,
    method: 'get',
    params: data
  })
}

export function addAccount(data) {
  return request({
    url: `/api/v1/manager/add_account`,
    method: 'post',
    data
  })
}

export function updateAccount(data) {
  return request({
    url: `/api/v1/manager/update_account`,
    method: 'post',
    data
  })
}
