<template>
  <div class="log-components" :style="{ width: width, height: height }">
    <div class="log-components-title">
      <span>{{ title }}</span>
    </div>
    <div class="log-content" v-if="logList.length > 0">
      <div class="log-item" v-for="(item, i) in logList" :key="i">
        <span
          class="log-item-label"
          :class="selectIndex == i ? 'select-label' : ''"
          @click="selectItem(item, i)"
        >
          {{ item.target_account }}
        </span>
      </div>
    </div>
    <noData v-else fontSize="14px" text="暂无查询记录" />
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    title: {
      type: String,
      default: '近10条手机号查询记录'
    },
    logList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      selectIndex: null
    }
  },
  methods: {
    selectItem(row, index) {
      this.selectIndex = index
      this.$emit('selectLog', row)
    }
  }
}
</script>

<style lang="scss" scoped>
.log-components {
  position: relative;
  .log-components-title {
    border-bottom: 1px solid #fbf6f6;
    span {
      border-left: 5px solid rgb(39, 154, 251);
      font-size: 14px;
      font-weight: 300;
      color: #2a2a2a;
      line-height: 40px;
      padding-left: 10px;
    }
  }
  .log-content {
    width: 100%;
    height: calc(100% - 50px);
    overflow: hidden;
    overflow-y: auto;
    // padding-left: 20px;
    margin-top: 10px;

    .log-item {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 15px 0;

      .log-item-label {
        font-size: 14px;
        font-weight: 300;
        color: #2a2a2a;
        padding: 0 10px;
        cursor: pointer;
      }
      .select-label {
        background: #efefef;
        border: 0px solid #000000;
        border-radius: 12px;
      }
    }
  }
}
</style>
