<template>
  <div class="table-components">
    <el-table
      :data="tableData"
      header-row-class-name="tableComheader"
      :style="styleObj"
      :height="height"
      :max-height="maxHeight"
      :row-style="rowStyle"
      :cell-style="cellStyle"
    >
      <el-table-column
        v-for="(item, i) in tableHead"
        :width="item.width"
        :key="i"
        :prop="item.prop"
        :label="item.label"
        :show-overflow-tooltip="!item.overflow"
      ></el-table-column>
      <slot></slot>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    tableHead: {
      type: Array,
      default: []
    },
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    height: {
      type: [String, Number],
      default: null
    },
    maxHeight: {
      type: String,
      default: '600px'
    },
    styleObj: {
      type: Object,
      default: () => {
        return {
          margin: 0
        }
      }
    },
    rowStyle: {
      type: Object,
      default: () => {
        return {}
      }
    },
    cellStyle: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table-components {
  ::v-deep .tableComheader th {
    background: rgb(243, 247, 249);
    padding: 8px 0;
  }
}
</style>
